@import "open-color/open-color";

.excalidraw {
  .library-menu-items-container {
    .library-actions {
      display: flex;

      button .library-actions-counter {
        position: absolute;
        right: 2px;
        bottom: 2px;
        border-radius: 50%;
        width: 1em;
        height: 1em;
        padding: 1px;
        font-size: 0.7rem;
        background: #fff;
      }

      &--remove {
        background-color: $oc-red-7;
        &:hover {
          background-color: $oc-red-8;
        }
        &:active {
          background-color: $oc-red-9;
        }
        svg {
          color: $oc-white;
        }
        .library-actions-counter {
          color: $oc-red-7;
        }
      }

      &--export {
        background-color: $oc-lime-5;

        &:hover {
          background-color: $oc-lime-7;
        }

        &:active {
          background-color: $oc-lime-8;
        }
        svg {
          color: $oc-white;
        }
        .library-actions-counter {
          color: $oc-lime-5;
        }
      }

      &--publish {
        background-color: $oc-cyan-6;
        &:hover {
          background-color: $oc-cyan-7;
        }
        &:active {
          background-color: $oc-cyan-9;
        }
        svg {
          color: $oc-white;
        }
        label {
          margin-left: -0.2em;
          margin-right: 1.1em;
          color: $oc-white;
          font-size: 0.86em;
        }
        .library-actions-counter {
          color: $oc-cyan-6;
        }
      }

      &--load {
        background-color: $oc-blue-6;
        &:hover {
          background-color: $oc-blue-7;
        }
        &:active {
          background-color: $oc-blue-9;
        }
        svg {
          color: $oc-white;
        }
      }
    }
    &__items {
      max-height: 50vh;
      overflow: auto;
      margin-top: 0.5rem;
    }

    .separator {
      font-weight: 500;
      font-size: 0.9rem;
      margin: 0.6em 0.2em;
      color: var(--text-primary-color);
    }
  }
}
