@import "open-color/open-color";

.excalidraw {
  .layer-ui__wrapper {
    z-index: var(--zIndex-layerUI);

    &__top-right {
      display: flex;
    }

    &__footer {
      width: 100%;

      &-right {
        z-index: 100;
        display: flex;
      }
    }

    .zen-mode-transition {
      transition: transform 0.5s ease-in-out;

      :root[dir="ltr"] &.transition-left {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.transition-right {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-left {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-right {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.layer-ui__wrapper__footer-left--transition-left {
        transform: translate(-76px, 0);
      }
      :root[dir="rtl"] &.layer-ui__wrapper__footer-left--transition-left {
        transform: translate(76px, 0);
      }

      &.layer-ui__wrapper__footer-left--transition-bottom {
        transform: translate(0, 92px);
      }
    }

    .disable-zen-mode {
      height: 30px;
      position: absolute;
      bottom: 10px;
      [dir="ltr"] & {
        right: 15px;
      }
      [dir="rtl"] & {
        left: 15px;
      }
      font-size: 10px;
      padding: 10px;
      font-weight: 500;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s linear 0s, opacity 0.5s;

      &--visible {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear 300ms, opacity 0.5s;
        transition-delay: 0.8s;
      }
    }

    .layer-ui__wrapper__footer-center {
      pointer-events: none;
      & > * {
        pointer-events: all;
      }
    }
    .layer-ui__wrapper__footer-left,
    .layer-ui__wrapper__footer-right,
    .disable-zen-mode--visible {
      pointer-events: all;
    }

    .layer-ui__wrapper__footer-left {
      margin-bottom: 0.2em;
    }

    .layer-ui__wrapper__footer-right {
      margin-top: auto;
      margin-bottom: auto;
      margin-inline-end: 1em;
    }
  }
}
